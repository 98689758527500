import { useEffect } from 'react';
import { useSession } from '@side/next-auth/react';
import { datadogRum } from '@datadog/browser-rum';
import { usePathname } from 'next/navigation';
import {
  identify,
  initialize as initAnalytics,
  routeChangeHandler,
} from '@side/analytics';

initAnalytics({ app: 'identity-ui' });

export function AnalyticsIdentify() {
  const { data } = useSession();
  const pathname = usePathname();
  const user = data?.user;

  // Page event tracker which reports to analytics
  useEffect(() => {
    if (pathname) {
      routeChangeHandler(pathname);
    }
  }, [pathname]);

  useEffect(() => {
    if (user) {
      const { id, email, displayName, photoUrl } = user;
      identify(id, {
        email,
        name: displayName,
        avatar: photoUrl,
      });

      // Track user in Datadog RUM
      datadogRum.setUser({
        email: email || '',
        name: displayName || '',
        id,
      });
    }
  }, [user]);

  return null;
}
